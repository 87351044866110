<template>
	<!-- Stepper Start -->
	<v-stepper v-model="e1" class="border">
		<v-stepper-header>
			<v-stepper-step :complete="e1 > 1" step="1">
				Select Users
			</v-stepper-step>

			<v-divider></v-divider>

			<v-stepper-step :complete="e1 > 2" step="2">
				Send Emails
			</v-stepper-step>

			<v-divider></v-divider>

			<v-stepper-step :complete="e1 > 3" step="3">
				Emails Sent
			</v-stepper-step>
		</v-stepper-header>

		<v-stepper-items>
			<!-- Step 1 start -->
			<v-stepper-content step="1">
				<v-card class="mb-12" color="grey lighten-1">
					<!-- Start Error Dialog -->
				<v-dialog v-model="errorDialog" max-width="500px">
					<v-card>
						<v-alert
						type="error"
						prominent
						border="left"
						dismissable
						close-text="Close Alert"
						class="ma-0"
					>
						<v-row align="center">
							<v-col class="grow">
								{{errorMessage}}
							</v-col>
							<v-col class="shrink">
								<!-- <v-btn>Take action</v-btn> -->
								<v-btn color="white" text @click="errorDialog = false"><v-icon>mdi-close</v-icon></v-btn>
							</v-col>
						</v-row>
						</v-alert>
					</v-card>
				</v-dialog>
					<v-data-table
						:headers="headers"
						:items="customers"
						sort-by="id"
						class="elevation-1"
						:search="search"
						show-select
						v-model="selected"
						item-key="id"
						:loading="loading"
						loading-text="Loading... Please wait">
						<template v-slot:top>
							<v-toolbar flat>
								<v-toolbar-title>Send Emails</v-toolbar-title>
								<v-divider class="mx-4" inset vertical></v-divider>
							</v-toolbar>
							<div class="pa-5">
								<v-select
									label="Card Group"
									:disabled="loading"
									v-model="selectedCardGroup"
									:items="activeGroups"
									item-text="name"
							></v-select>
							<v-select
								label="Definition key"
								v-model="selectedDefinitionKey"
								:items="$store.state.definitions"
								item-text="definitionKey"
							></v-select>
							<v-btn color="primary" @click="preview()" :disabled="customers.length === 0 || !selectedCardGroup || !selectedDefinitionKey">
								<v-icon class="mr-2">mdi-eye</v-icon> Preview
							</v-btn>
							<v-icon v-if="previewStatusIcon" class="ml-2">{{ previewStatusIcon }}</v-icon>
							<v-text-field
								v-model="search"
								:disabled="loading"
								append-icon="mdi-magnify"
								label="Search"
								single-line
								hide-details
							></v-text-field>
							</div>
						</template>
						<template v-slot:item.cards>
							{{ selectedCardGroup }}
						</template>
						<template v-slot:item.epochTimeOfLastSentEmail="{ item }">
							{{ item.timeOfLastSentEmail }}
						</template>
						<template v-slot:no-data>
							<v-btn color="primary" @click="initialize()"> Reset </v-btn>
						</template>
					</v-data-table>
				</v-card>

				<v-btn color="primary" @click="e1 = 2" :disabled="selected.length <= 0">
					Continue
				</v-btn>
			</v-stepper-content>
			<!-- Step 1 end -->

			<!-- Step 2 start -->
			<v-stepper-content step="2">
				<v-card class="mb-12" color="grey lighten-1">
					<v-data-table
						:headers="headers"
						:items="selected"
						sort-by="id"
						class="elevation-1"
						:search="search"
						v-model="selected"
						item-key="id"
						loading-text="Loading... Please wait"
						:loading="loading">
						<template v-slot:top>
							<v-toolbar flat>
								<v-toolbar-title>Customers Wizard</v-toolbar-title>
								<v-divider class="mx-4" inset vertical></v-divider>

							</v-toolbar>

							<v-text-field
								class="pa-5"
								v-model="search"
								append-icon="mdi-magnify"
								label="Search"
								single-line
								hide-details>
							</v-text-field>
						</template>
						<template v-slot:item.cards>
							{{ selectedCardGroup }}
						</template>
							<template v-slot:no-data>
								<v-btn color="primary" @click="initialize()"> Reset </v-btn>
							</template>
				</v-data-table>
				</v-card>

				<v-btn :disabled="sendEmailsFlag" color="primary" @click="sendEmails()">
					Send Emails
				</v-btn>

				<v-btn text @click="e1 = 1">
					Back
				</v-btn>
			</v-stepper-content>
						<!-- Step 2 end -->

			<!-- Step 3 start -->
			<v-stepper-content step="3">
				<v-card class="mb-12">
					<v-row justify="center" align="center">
						<v-col cols="12" sm="6" md="4" >
							<!-- <v-card height="300px"> -->
							<h1 class="blue--text" justify="center" align="center">Emails Sent
								<v-icon color="green" large justify="center" align="center">mdi-checkbox-marked-circle</v-icon>
							</h1>
							<!-- </v-card> -->
						</v-col>
					</v-row>
				</v-card>
				<v-btn color="primary" @click="e1 = 1">
					Return
				</v-btn>

				<!-- <v-btn text @click="e1 = 1">
					Back
				</v-btn> -->
			</v-stepper-content>
			<!-- Step 3 end -->
		</v-stepper-items>
	</v-stepper>
</template>

<script>
import axios from "axios";
import { stringifyArray } from '../helpers/util';

export default {
	data: () => ({
		e1: 1,
		selected: [],
		selectedIds: [],
		dialog: false,
		search: "",
		files: [],
		headers: [
			{
				text: "Name",
				value: "firstName",
			},
			{ text: "Email", value: "email" },
			{ text: "Company", value: "company" },
			{ text: "Group Name", value: "cards" },
			{ text: "Last sent date", value: "epochTimeOfLastSentEmail" }
		],
		editedIndex: -1,
		loading: true,
		errorDialog: false,
		errorMessage: "",
		sendEmailsFlag: false,
		selectedCardGroup: null,
		customers: [],
		selectedDefinitionKey: null,
		previewStatusIcon: null
	}),
	async created() {
		await this.$store.dispatch("getDefinitions", {
			token: await this.$auth.getAccessToken()
		});
	},
	computed: {
		activeGroups() {
			return this.$store.state.activeGroups || [];
		}
	},

	watch: {
		selectedCardGroup(group) {
			group && this.getUsers(group);
		},
		activeGroups: {
			handler() {
				if (this.selectedCardGroup === null && this.activeGroups.length > 0) {
					this.selectedCardGroup = this.activeGroups[0].name;
				}
			},
			immediate: true
		}
	},
	methods: {
		getUsers(group) {
			this.loading = true;
			this.customers = this.$store.getters.customersInGroup(group);
			if (this.customers.length > 0) {
				for (let i = 0; i < this.customers.length; i++) {
					const customer = this.customers[i];
					for (let j = 0; j < customer.cards.length; j++) {
						const card = customer.cards[j];
						if (card.name === group && card.timeOfLastSentEmail) {
							customer.timeOfLastSentEmail = card.timeOfLastSentEmail;
							var dateString = card.timeOfLastSentEmail;
var dateParts = dateString.split(/[\s,/:]+/); // Split the date string using spaces, comma, colons, and slashes

// Create a new Date object by reordering the date parts
var date = new Date(
  Date.UTC(
    parseInt(dateParts[2], 10),  // Year
    parseInt(dateParts[1], 10) - 1,  // Month (0-based)
    parseInt(dateParts[0], 10),  // Day
    parseInt(dateParts[3], 10),  // Hours
    parseInt(dateParts[4], 10),  // Minutes
    parseInt(dateParts[5], 10)   // Seconds
  )
);

var epochTime = date.getTime();

							customer.epochTimeOfLastSentEmail = epochTime;
							break;
						}
					}
				}
			}			
			this.loading = false;
		},
		print() {
			window.print();
		},
    async preview() {
		this.previewStatusIcon = 'mdi-email-arrow-right-outline';
		axios
        .post(
          this.$apiUrl,
          {
            query: `mutation{
						previewEmail(previewEmailInput: { cardGroup:"${this.selectedCardGroup}",definitionKey: "${this.selectedDefinitionKey}" })
						{
							success
							}
					}`,
          },
          {
            headers: {
              Authorization: "Bearer " + (await this.$auth.getAccessToken()),
            },
          }
        )
        .then((response) => {
          if (response.data.errors == undefined) {
            console.log(response.data.data.previewEmail);
            if (response.data.data.previewEmail.success) {
				this.previewStatusIcon = 'mdi-check';
            } else {
				this.previewStatusIcon = 'mdi-alert-circle-outline';
			}
          }
        }).catch(() => {
			this.previewStatusIcon = 'mdi-alert-circle-outline';
		});
    },
		async sendEmails(){
			this.sendEmailsFlag = true;
			this.loading = true;
			for (let index = 0; index < this.selected.length; index++) {
				// const element = this.selected[index];
				const { cards } = this.selected[index];
				if (-1 !== cards.findIndex(card => card.name === this.selectedCardGroup)) {
					this.selectedIds.push(cards[cards.findIndex(card => card.name === this.selectedCardGroup)].id);
				}
			}
			axios
				.post(
					this.$apiUrl,
					{
						query: `mutation {
							bulkSendEmail(ids: ${stringifyArray(this.selectedIds)}, definitionKey: "${this.selectedDefinitionKey}"){
								success
							}
						}`
					},
					{
						headers: {
              Authorization: "Bearer " + await this.$auth.getAccessToken(),
						}
					}
				)
				.then(response => {
					if(response.data.errors == undefined){
						if(response.data.data.bulkSendEmail.success){
							this.e1 = 3;
							this.sendEmailsFlag = false;
							this.loading = false;
							this.selected = [];
							this.getUsers(this.selectedCardGroup);
						}
					}else{
						console.log(response.data.errors, 'errors');
						this.errorMessage = response.data.errors[0].message;
						this.errorDialog = true;
						this.e1 = 1;

						this.sendEmailsFlag = false;
						this.loading = false;
					}
				}).finally(() => {
					this.selectedIds = [];
				});
		}
	},
};
</script>

<style scoped>
.border {
	border: 1px solid #007bff;
}

input {
	color: #007bff;
}
</style>
